import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import logo from './logo3d.svg';
import '../App.css';

const OnboardingIos = () => {
  const [email, setEmail] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  const generateRandomCode = async () => {
    // Longitud máxima del código
    const maxLength = 6;
    // Caracteres posibles para el código
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';

    // Genera el código aleatorio
    for (let i = 0; i < maxLength; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    setCode(code);

    const documentData = {
      code: code,
      singleUse: false,
      usesRemaining: 2
    };

    // Agrega el documento a la colección 'invitations'
    try {
      await firebase.firestore().collection('invitations').doc(code).set(documentData);
      console.log('Documento agregado con éxito:', documentData);
    } catch (error) {
      console.error('Error al agregar el código:', error);
    }
  };

  const handleSendEmail = async () => {
    setIsDeleting(true);
    const user = firebase.auth().currentUser;
    try {
      if (user != null) {
        await generateRandomCode();
        const emailCode = encodeURIComponent(code.toLowerCase());
        const emailParam = encodeURIComponent(userEmail);
        const apiUrl = `${process.env.REACT_APP_ONB_API_URL}?email=${emailParam}&code=${emailCode}`;

        console.log(apiUrl)
        const response = await fetch(apiUrl, {
          method: 'GET', // Cambiamos a GET
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          setEmailSent(true);

        } else {
          console.error('Failed to send email');
        }
      }

    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        return false;
      } else {
        console.error('Error al eliminar la cuenta:', error);
        throw error;
      }
    } finally {
      setIsDeleting(false);
    }
  };


  const handleLogin = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const user = await firebase.auth().currentUser;

      if (user) {
        const usuarioPerfilSnap = await firebase.firestore()
          .collection('users')
          .doc(user.uid)
          .get();
        const usuarioPerfilData = usuarioPerfilSnap.data();
        console.log(usuarioPerfilData)
        if (usuarioPerfilData.isAdmin) {
          setShowLoginForm(false)
        }
      }

    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      alert('Credenciales inválidas. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <img src={logo} alt="Scala" className="logo" />
        <div className="content">
          {!showLoginForm ? (
            <div className="form-container">
              <span className="message">Enviar Onboarding Mail en iOS</span>
              <input
                type="email"
                placeholder="Escribe el email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <button
                onClick={handleSendEmail}
                disabled={isDeleting}
                className="delete-button"
              >
                Enviar Correo
              </button>
              <button
                onClick={generateRandomCode}
                disabled={isDeleting}
                className="button"
              >
                Generar codigo
              </button>
              {code && (
                <h1>
                  {code}
                </h1>
              )}
              {emailSent && (
                <div className="success-message">
                  ¡El correo ha sido enviado con éxito!
                </div>
              )}
            </div>
          ) : (
            <div className="form-container">
              <input
                type="email"
                placeholder="Escribe tu email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Escribe tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                disabled={!email || !password}
                onClick={handleLogin}
                className="login-button"
              >
                Iniciar sesión
              </button>
            </div>
          )}
        </div>
        <style jsx>{`
              .container {
                  display: flex;
                  justify-content: center; 
                  align-items: center; 
                  height: 100vh;
                  width: 100%;
                  margin: 0;
              }
              .logo {
                  margin-bottom: 0;
                  width: 300px;
              }
              .title {
                  font-size: 24px;
                  margin-bottom: 20px;
              }
              .content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 70%;
              }
              .delete-button {
                  background-color: #FF4A4A;
                  color: #ffffff;
                  padding: 10px 20px;
                  border: none;
                  border-radius: 5px;
                  margin-bottom: 20px;
              }
              .form-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
              }
              .message {
                  margin-bottom: 20px;
                  font-size: 18px;
              }
              input {
                  width: 100%;
                  padding: 10px;
                  margin-bottom: 20px;
                  border: 1px solid #ced4da;
                  border-radius: 5px;
              }
              .login-button {
                  background-color: #222;
                  color: #ffffff;
                  padding: 10px 20px;
                  border: none;
                  border-radius: 5px;
                  font-size: 16px;
                  cursor: pointer;
              }
              .login-button:disabled {
                  opacity: 0.5;
                  cursor: not-allowed;
              }
          `}</style>
      </div>
    </div>
  );
};

export default OnboardingIos;
