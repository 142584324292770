import React from 'react';
import '../App.css';

const Mission = () => {
  return (
    <div className="mission-container">
      <h1>Misión</h1>
      <p>En Scala, abrazamos la idea de que el logro de metas personales 🥇 no solo es un viaje individual 👤, sino una experiencia compartida 👥 que puede inspirar ✨ y motivar a otros.</p>
      <p>Nos dedicamos a construir una comunidad 🌐 donde cada individuo puede visualizar 👀 y celebrar 🎉 los éxitos de los demás, encontrando así la inspiración necesaria para alcanzar 📈 sus propios objetivos ✅.</p>
      <p>Nuestra misión 🚀 es proporcionar un espacio digital 💻 donde las personas se conecten a través de sus aspiraciones 🎖 y triunfos . En Scala, creemos en el poder de la motivación 🔥 colectiva.</p>
      <p>Además de permitir a nuestros usuarios 👤 compartir 📤 sus metas personales, ofrecemos la oportunidad de unirse para lograr 🎢 objetivos grupales. Scala transforma aspiraciones individuales en acciones 😎 colectivas.</p>
    </div>
  );
}

export default Mission;
