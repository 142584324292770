import React, {useEffect} from "react";
import HeroHeader from "./HeroHeader";

import './style.css';
import HeroCollage from "./ImageComponent";
import FeatureSlides from "./FeatureSlides";
import Footer from '../../components/Footer';
import HeroDownload from "./HeroDownload";
import WaitlistButton from "../WaitlistButtonTop"
import WaitlistButtonMobile from "../WaitlistButtonTopMobile"
import HeroMission from "./HeroMission";
import HeroFAQ from "./HeroFAQ";


export default function Hero() {

  useEffect(() => {

    const updateBody = () => {
      const body = document.body;

      body.classList.add("body-background");


    };

    updateBody();
  });

  return (
    <div>
      <WaitlistButton/>
      <WaitlistButtonMobile/>
    <main className='parallaxContent'>
      <div className="hero-container">
        <HeroHeader  />
        <HeroCollage />
        <FeatureSlides />
        <HeroDownload/>
        <HeroMission/>
        <HeroFAQ/>
        </div >
    </main>
      
    <Footer />
    </div >
    
  );
}