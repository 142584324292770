import React, { useEffect } from 'react';

const Download = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Si el dispositivo es Android
      window.location.href = 'https://play.google.com/store/apps/details?id=com.scala.app';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Si el dispositivo es iOS
      window.location.href = 'https://apple.co/4bugtF0';
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      // Si el dispositivo es macOS
      window.location.href = 'https://apple.co/4bugtF0';
    } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
      // Si el dispositivo es Windows
      window.location.href = 'https://play.google.com/store/apps/details?id=com.scala.app';
    } else {
      // Si no es Android, iOS, macOS ni Windows
      window.location.href = 'https://play.google.com/store/apps/details?id=com.scala.app';
    }
  }, []);

  return (
    <div style={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
        textAlign: 'center',
        background: '#fff',
      }}>
        <h1>Redirigiendo...</h1>
      </div>
  );
}

export default Download;
