import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import image1 from './assets/01.jpg';
import image2 from './assets/02.jpg';
import image3 from './assets/03.jpg';
import image4 from './assets/04.jpg';

gsap.registerPlugin(ScrollTrigger);

const photos = [image1, image2, image3, image4];

function ImageElement({ src, index }) {
  return (
    <div className={`hero-element hero-element-${index}`}>
      <img src={src} className="collage-element" alt="" />
    </div>
  );
}

export default function HeroCollage() {
  useEffect(() => {
    const tl = gsap.timeline({
      delay: 0.3,
    });
    tl.fromTo(
      ".hero-element",
      { y: 500, },
      {
        duration: 1,
        y: 0,
        opacity: 1,
        delay: function(i) {
          return 0.1 * i;
        },
      }
    );

    gsap.to(".hero-element", {
      y: 0,
      scrollTrigger: {
        trigger: ".hero-text-section",
        start: "top top",
        end: "bottom top",
        scrub: true,
        onEnter: () => {
            gsap.to(".hero-element", { y: 6000,opacity: 0, duration: 0.5 }).then(() => {
              gsap.to(".hero-element", { opacity: 0, duration: 0 });
            });
          },        
        onLeaveBack: () => gsap.to(".hero-element", { y: 0, opacity: 1, duration: 0.2 }),
        onEnterBack: () => {
          gsap.to(".hero-element", { y: 6000,opacity: 0, duration: 0.5 }).then(() => {
            gsap.to(".hero-element", { opacity: 0, duration: 0 });
          });
        },        
      }
    });
    }, []);
  
    return (
      <div className="hero-collage">
        {photos.map((photo, index) => (
          <ImageElement key={index} src={photo} index={index} />
        ))}
      </div>
    );
  }
