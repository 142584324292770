import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from 'classnames';
import image1 from './assets/252.jpg';
import image2 from './assets/253.jpg';
import image3 from './assets/254.jpg';
import image4 from './assets/255.jpg';
import image5 from './assets/257.jpg';
import image7 from './assets/258.png';
import image6 from './assets/256.jpg';
import FeatureSlide from "./FeatureSlide";

gsap.registerPlugin(ScrollTrigger);

const featureSlides = [
  { imageUrl: image2, title: "Crea tus objetivos y retos", description: "Elige la categoría de tu publicación y comienza con tus logros", borderColor: "#5F8DFF" },
  { imageUrl: image3, title: "Cumple lo que te propongas", description: "Podrás crear y publicar tus objetivos en un solo lugar", borderColor: "#8B73FF" },
  { imageUrl: image4, title: "Motívate con los logros de tus amigos", description: "Inspírate con los éxitos de tus amigos en la pantalla de inicio", borderColor: "#FF7BAA" },
  { imageUrl: image1, title: "Únete a retos y conoce a personas", description: "Participa en retos y conecta con personas con tus mismos gustos", borderColor: "#16D161" },
  { imageUrl: image6, title: "Explora nuevos perfiles y logros", description: "Descubre historias y metas que te inspirarán a seguir adelante", borderColor: "#FFD950" },
  { imageUrl: image5, title: "Disfruta del modo oscuro", description: "Mejora tu experiencia con un diseño cómodo para tus ojos en cualquier entorno", borderColor: "transparent" },
  { imageUrl: image7, title: "Empieza tu día con Scala", description: "Recibe una notificación diaria con una frase motivacional", borderColor: "#FF5242" },
];

function RenderImages({ activeFeatureIndex }) {
  return featureSlides.map(({ imageUrl, borderColor }, index) => (
    <img
      className={cn(`feature-image feature-image-${index}`, { "as-primary": activeFeatureIndex === index })}
      src={imageUrl}
      key={imageUrl}
      style={{ borderColor : "transparent" }}
      alt={`Slide ${index}`}
    />
  ));
}

function RenderBackgroundGradient({ activeFeatureIndex }) {
  return (
    <div
      className="background-gradient"
      style={{ background: `${featureSlides[activeFeatureIndex].borderColor}` }}
    />
  );
}

export default function FeatureSlides() {
  const [activeFeatureIndex, setFeatureIndex] = useState(0);
  const featureSliderRef = useRef(null);
  const featureSlidesLeftRef = useRef(null);
  

  useEffect(() => {

    const updateMobileClass = () => {
      const featureSlidesRight = document.querySelector(".feature-slides-right");
      const featureSlides4 = document.querySelector(".feature-slide-4");
      const featureSlides5 = document.querySelector(".feature-slide-5");
      if (window.innerWidth > 768) {
        featureSlidesRight.classList.add("mobile-gradient");
        featureSlides4.classList.remove("black-background")
        featureSlides5.classList.remove("white-background")
      } else {
        featureSlides4.classList.add("black-background")
        featureSlides5.classList.add("white-background")
        featureSlidesRight.classList.remove("mobile-gradient");
      }
    };

    updateMobileClass();

    // Add event listener for window resize
    window.addEventListener("resize", updateMobileClass);
  
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: featureSlidesLeftRef.current,
          start: "top top",
          end: () => `+=${featureSliderRef.current.offsetHeight * 0.85}`,
          scrub: true,
          pin: true,
          toggleActions: 'restart none none reverse',
          onEnter: () => {
            gsap.to(".background-gradient", { 
              opacity: 1,
              duration: 0.3,
            });
          },
          onLeaveBack: () => {
            gsap.to(".background-gradient", { 
              opacity: 0,
              duration: 0.1,
            });
          }
        },
      });
  

      ScrollTrigger.create({
        trigger: `.feature-slide-5, .black-background`,
        start: "top center",
        end: "bottom center",
        toggleActions: 'play reverse play reverse',
        scrub: true,
        onEnter: () => {
          gsap.to(".background-gradient", {
            background: "transparent",
            duration: 0.3,
          });
          gsap.to(".feature-slides-container", {
            backgroundColor: "#111",
            color: "#fff",
            duration: 0.3,
          });
          gsap.to(".feature-slides-right", {
            background: "linear-gradient(180deg, rgba(17, 17, 17, 1) 85%, rgba(255, 255, 255, 0) 100%)",
            duration: 0.3,
          });
          gsap.to(".input-top", {
            backgroundColor: "#151515",
            color: "#fff",
            borderColor: "#666",
            duration: 0.3,
          });
          gsap.to(".button-top-right", {
            backgroundColor: "#151515",
            color: "#fff",
            borderColor: "#666",
            duration: 0.3,
          });
        },
        onEnterBack: () => {
          gsap.to(".background-gradient", {
            background: "transparent",
            duration: 0.3,
          });
          gsap.to(".feature-slides-container", {
            backgroundColor: "#111",
            color: "#fff",
            duration: 0.3,
          });
          gsap.to(".feature-slides-right", {
            background: "linear-gradient(180deg, rgba(17, 17, 17, 1) 85%, rgba(255, 255, 255, 0) 100%)",
            duration: 0.3,
          });
          gsap.to(".input-top", {
            backgroundColor: "#151515",
            color: "#fff",
            borderColor: "#666",
            duration: 0.3,
          });
          gsap.to(".button-top-right", {
            backgroundColor: "#151515",
            color: "#fff",
            borderColor: "#666",
            duration: 0.3,
          });
        },
        onLeaveBack: () => {
          gsap.to(".background-gradient", {
            background: "#FFD950",
            duration: 0.3,
          });
          gsap.to(".feature-slides-right", {
            background: "linear-gradient(180deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)",
            duration: 0.3,
          });
          gsap.to(".feature-slides-container", {
            backgroundColor: "#fff",
            color: "#000",
            duration: 0.3,
          });
          gsap.to(".input-top", {
            backgroundColor: "#fff",
            color: "#111",
            borderColor: "#ddd",
            duration: 0.3,
          });
          gsap.to(".button-top-right", {
            backgroundColor: "#fff",
            color: "#111",
            borderColor: "#111",
            duration: 0.3,
          });
        },
        onLeave: () => {
          gsap.to(".background-gradient", {
            background:"#FFD950",
            duration: 0.3,
          });
        }
      });


    ScrollTrigger.create({
      trigger: `.feature-slide-6, .white-background`,
      start: "top center",
      end: "bottom center",
      scrub: true,
      onLeaveBack: () => {
        gsap.to(".background-gradient", {
          background: "transparent",
          duration: 0.3,
        });
        gsap.to(".feature-slides-container", {
          backgroundColor: "#111",
          color: "#fff",
          duration: 0.3,
        });
        gsap.to(".feature-slides-right", {
          background: "linear-gradient(180deg, rgba(17, 17, 17, 1) 85%, rgba(255, 255, 255, 0) 100%)",
          duration: 0.3,
        });
        gsap.to(".input-top", {
          backgroundColor: "#151515",
          color: "#fff",
          borderColor: "#666",
        });
        gsap.to(".button-top-right", {
          backgroundColor: "#151515",
          color: "#fff",
          borderColor: "#666",
          duration: 0.3,
        });
      },
      onEnter: () => {
        gsap.to(".background-gradient", {
          background: "#FF5242",
          duration: 0.3,
        });
        gsap.to(".feature-slides-container", {
          backgroundColor: "#fff",
          color: "#000",
          duration: 0.3,
        });
        gsap.to(".feature-slides-right", {
          background: "linear-gradient(180deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)",
          duration: 0.3,
        });
        gsap.to(".input-top", {
          backgroundColor: "#fff",
          color: "#111",
          borderColor: "#ddd",
        });
        gsap.to(".button-top-right", {
          backgroundColor: "#fff",
          color: "#111",
          borderColor: "#111",
        });
      },
        onEnterBack: () => {
          gsap.to(".background-gradient", {
            background: "#FF5242",
            duration: 0.3,
          });
          gsap.to(".feature-slides-container", {
            backgroundColor: "#fff",
            color: "#000",
            duration: 0.3,
          });
          gsap.to(".feature-slides-right", {
            background: "linear-gradient(180deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%)",
            duration: 0.3,
          });
      }
    });

    // ScrollTrigger para cambiar la posición del background-gradient en la sección .footer
    ScrollTrigger.create({
      trigger: ".feature-slides-left",
      start: "bottom bottom", // Empieza cuando la parte superior de .footer está en el centro del viewport
      onEnter: () => {
        gsap.to(".background-gradient", {
          opacity: 0,
          duration: 0.3,
        });
      },
      onLeaveBack: () => {
        gsap.to(".background-gradient", {
          opacity: 1,
          duration: 0.3,
        });
      }
    });

    return () => {
      if (ScrollTrigger.getById(tl.scrollTrigger.id)) {
        ScrollTrigger.getById(tl.scrollTrigger.id).kill();
      }
      ScrollTrigger.getAll().forEach(trigger => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <div ref={featureSliderRef} className="feature-slides-container">
      <RenderBackgroundGradient activeFeatureIndex={activeFeatureIndex} />
      <div ref={featureSlidesLeftRef} className="feature-slides-right">
        <RenderImages activeFeatureIndex={activeFeatureIndex} />
      </div>
      <div className="feature-slides-left">
        {featureSlides.map((feature, index) => (
          <FeatureSlide
            updateActiveImage={setFeatureIndex}
            key={feature.imageUrl}
            title={feature.title}
            description={feature.description}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}