import React from 'react';
import '../App.css';
import LogoFooter from './layouts/LogoFooter';

const PrivacyPolicy = () => {
  return (
    <LogoFooter>
      <div className="container">
        <h1>Política de privacidad</h1>
        <div className='content'>
          <h3>Última actualización: 22 de octubre de 2024</h3>

          <p>Esta Política de Privacidad es parte integral del Acuerdo de Usuario, que también incluye nuestras Condiciones de Uso. Está diseñada para asegurarnos de que sepas qué información recopilamos sobre ti, cómo la utilizamos, cuándo la compartimos y qué opciones tienes para gestionar la información que compartes con nosotros. En el Acuerdo de Usuario, algunas palabras tienen significados específicos y deben interpretarse de la misma manera, independientemente del contexto en el que se utilicen.</p>

          <p>Si bien no podemos obligarte a leer el Acuerdo de Usuario, creemos que al hacerlo, comprenderás mejor cómo respetamos tu información.</p>

          <h2>1. Información que recibimos de ti</h2>

          <p>Cuando usas Scala, recopilamos información que nos proporcionas directamente (datos que nos envías) y también a través de tu uso de nuestros servicios (datos que recopilamos mientras utilizas nuestros servicios).</p>

          <h3>1.1. Datos que nos proporcionas directamente</h3>

          <li><strong>Datos de Creación de la Cuenta:</strong> estos son los datos necesarios para crear una cuenta en Scala. Sin ellos, no podemos ofrecerte nuestros Servicios. Esto puede incluir tu correo electrónico, nombre completo y un nombre de usuario. Durante la creación de la cuenta, también tienes la opción de compartir información adicional con nosotros, como tu foto de perfil.</li>

          <li><strong>Datos de Contenido:</strong> recibimos el contenido que creas al utilizar nuestros Servicios, como fotos, ubicación y mensajes. Ten en cuenta que otros usuarios pueden copiar tu contenido (por ejemplo, mediante capturas de pantalla) y distribuirlo a través de diferentes plataformas. Por esta razón, te aconsejamos que evites publicar cualquier contenido que no desees que sea accesible o replicado por otros.</li>

          <li><strong>Comunicaciones con Scala:</strong> cuando te comunicas con nosotros para informar un problema, solicitar asistencia o ejercer tus derechos, recibimos información necesaria para ayudarte y mejorar nuestros servicios.</li>

          <li><strong>Encuestas:</strong> Podemos contactarlo para participar en encuestas donde se le solicitará cierta información, que puede incluir información personal.</li>

          <h3>1.2 Datos que recopilamos cuando usas nuestros servicios</h3>

          <p>Además de la información que nos proporcionas, recibimos automáticamente información sobre ti y tu uso de nuestros servicios desde tu dispositivo. Esto puede incluir datos técnicos y de conexión, datos de interacciones y datos de uso. </p>

          <p>Cuando decides crear publicaciones, obtenemos acceso a la cámara y la biblioteca de fotos, si nos lo permites, de lo contrario, al denegar el acceso no podrás publicar en nuestra app.</p>

          <h2>2. Cómo usamos la información que recibimos de ti</h2>

          <p>En Scala, no vendemos tu información. Utilizamos tus datos con cuidado y atención para brindarte nuestros Servicios. Esto puede incluir el desempeño, la mejora y la entrega de nuestros Servicios, la seguridad y protección, mediciones y análisis, y comunicaciones contigo.</p>

          <p>El uso que hacemos de la información que recibimos es el siguiente:</p>

          <li><strong>Desempeño, mejora y suministro de nuestros Servicios:</strong> Este aspecto implica actividades como la creación de cuentas de usuario, la recepción y publicación de contenido. Nos esforzamos por garantizar que nuestros servicios funcionen según tus expectativas y estamos en constante búsqueda de mejoras.</li>

          <li><strong>Seguridad y protección:</strong> Nos tomamos muy en serio la seguridad tanto tuya como la de todos nuestros usuarios. Esto implica no solo la protección de tus datos, sino también asegurar la integridad y seguridad de nuestros servicios en general, así como el cumplimiento de todas las leyes y regulaciones pertinentes.</li>

          <li><strong>Mediciones y análisis:</strong> Constantemente evaluamos el desempeño de nuestros servicios para garantizar su eficacia y estabilidad. Esto incluye analizar el funcionamiento de la aplicación, identificar y solucionar problemas, así como también entender cómo interactúas con el contenido y las cuentas.</li>

          <li><strong>Comunicaciones:</strong> Valoramos tu interés y deseamos mantener una comunicación abierta contigo. Por tanto, utilizamos la información que recibimos para comunicarnos contigo sobre nuevas características o funciones de nuestros servicios, así como para responder a tus consultas o denuncias de contenido.</li>

          <li><strong>Investigación:</strong> Nos interesa conocer tus opiniones y comentarios como usuario. Siempre que llevamos a cabo investigaciones, te informaremos y tendrás la opción de colaborar. En estos casos, compartirás información con nosotros con el propósito de mejorar aún más nuestros servicios.</li>

          <p>Además, es importante señalar que, como parte de la funcionalidad de nuestros servicios, podemos hacer uso de Interfaces de Programación de Aplicaciones ("APIs") y Kits de Desarrollo de Software ("SDKs") proporcionados por terceros. Si deseas obtener más información sobre nuestro uso de estas herramientas, no dudes en contactarnos utilizando la información de contacto proporcionada en la sección "Contáctanos" a continuación.</p>

          <h2>3. Cómo compartimos información sobre ti</h2>

          <p>Solo compartimos tu información cuando es necesario para que puedas usar nuestros servicios o por requerimientos legales. Esto puede incluir compartir información con empleados de Scala, para poder mejorar nuestro software u ofrecerte asistencia técnica. Con otros usuarios dentro de la app para que podáis interactuar correctamente. Fines legales y normativos, para responder a solicitudes legales de información sobre usted o compartir datos de manera proactiva con las autoridades cuando descubrimos contenido que infrinja la ley.</p>

          <h2>4. ¿Cuánto tiempo conservamos tus datos?</h2>

          <p>Nuestro objetivo es conservar tu información solo durante el tiempo necesario para proporcionarte nuestros Servicios. </p>

          <p>Los datos que nos proporcionas para crear tu cuenta se mantienen mientras tu perfil se encuentre activo. Una vez eliminas tu cuenta, se eliminarán estos datos.</p>

          <p>La información usada para añadir contenido a la app, se conserva hasta que decidas eliminar las publicaciones, o por lo contrario, como máximo una semana después de eliminar tu cuenta.</p>

          <h2>5. Tus opciones para gestionar tu información</h2>

          <p>En Scala, te ofrecemos distintas opciones para gestionar tu información, desde editar los datos de tu perfil hasta diferentes ajustes que podrás encontrar dentro del apartado superior de tu cuenta, desde donde podrás acceder a la opción de eliminar tu cuenta. Este proceso tarda alrededor de unos 7 días en hacerse efectivo y eliminar todo tu contenido publicado en la app.</p>

          <h2>6. Qué derechos tienes sobre sus datos</h2>

          <p>La privacidad es un derecho humano esencial. Por ello, todas las personas que utilizan nuestros Servicios, independientemente de su lugar de residencia, pueden esperar el mismo nivel de protección y respeto por parte de nuestra organización. Ya sea que se encuentre en Europa, en uno de los 50 estados de los EE.UU., América Latina, Oriente Medio, África, Japón, Asia-Pacífico o cualquier otro lugar, puede ejercer los siguientes derechos:</p>

          <ul>
            <li>Solicitar la eliminación de su cuenta a través de las opciones de configuración en nuestras aplicaciones o contactando con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Informarse sobre con quién compartimos sus datos, consultando esta lista de proveedores de servicios y socios, o contactando con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Solicitar una copia de la información que tenemos sobre usted contactando con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Rectificar cualquier información inexacta a través de los controles disponibles en la aplicación o contactando con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Recurrir cualquier decisión relacionada con el tratamiento de sus datos contactando con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Si tiene alguna duda sobre nuestras prácticas de privacidad, no dude en contactar con nuestro equipo de asistencia sobre privacidad;</li>
            <li>Contactar directamente con nuestra Oficina de Protección de Datos a través de nuestro equipo de asistencia sobre privacidad para cualquier asunto relacionado con la protección de sus datos personales.</li>
          </ul>

          <h2>7. Contacto</h2>

          <p>Si tienes preguntas sobre nuestra política de privacidad o prácticas de privacidad, puedes contactarnos en info@scala-app.com.</p>
        </div>
      </div>
    </LogoFooter>
  );
}

export default PrivacyPolicy;
