import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import TermsOfUse from './screens/TermsOfUse';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Mission from './screens/Mision';
import DeleteAccountScreen from './screens/DeleteAccount';
import firebase from 'firebase/compat/app'; //v9

import 'firebase/compat/auth'; //v9

import 'firebase/compat/firestore';
import Contact from './screens/Contact';
import OnboardingIos from './screens/OnboardingIos';
import OnboardingAnd from './screens/OnboardingAnd';
import Hero from './screens/components/Hero/Hero';
import Download from './screens/Download';

const firebaseConfig = {
  apiKey: "AIzaSyATrumynJOb80dTIGvBNAQsug7DTvEl_QI",
  authDomain: "scala-com.firebaseapp.com",
  projectId: "scala-com",
  storageBucket: "scala-com.appspot.com",
  messagingSenderId: "694030752668",
  appId: "1:694030752668:web:6b3603720bff34fb494659",
  measurementId: "G-NFVN4F9KYQ"
};

firebase.initializeApp(firebaseConfig);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/delete-account" element={<DeleteAccountScreen />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/onboarding-ios" element={<OnboardingIos />} />
        <Route path="/onboarding-android" element={<OnboardingAnd />} />
        <Route path="/launch" element={<Hero />} />
        <Route path="/download" element={<Download />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

