import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import logo from './logo3d.svg';
import '../App.css';

const DeleteAccountScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(true);

    const deleteAccount = async () => {
        const user = firebase.auth().currentUser;
        try {
            if (user != null) {
                const deletedUser = {
                    userId: user.uid,
                    email: user.email,
                    deletionDate: new Date(),
                };
                await user.delete();
                await firebase.firestore().collection('deletedUsers').doc(user.uid).set(deletedUser);
                return true;
            }

        } catch (error) {
            if (error.code === 'auth/requires-recent-login') {
                return false;
            } else {
                console.error('Error al eliminar la cuenta:', error);
                throw error;
            }
        }
    };

    const handleDeleteAccount = async () => {
        setIsDeleting(true);
        try {
            const isDeleted = await deleteAccount();
            if (isDeleted) {
                alert('La cuenta ha sido eliminada exitosamente.');
            } else {
                setShowLoginForm(true);
                alert('Se requiere autenticación para realizar esta acción. Por favor, vuelve a iniciar sesión.');
            }
        }
        catch (error) {
            console.error('Error:', error);
        } finally {
            setIsDeleting(false);
        }
    };


    const handleLogin = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            setShowLoginForm(false)
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            alert('Credenciales inválidas. Por favor, inténtalo de nuevo.');
        }
    };

    return (
        <div className="container">
          <div className="form-container">
          <img src={logo} alt="Scala" className="logo" />
            <div className="content">
                {!showLoginForm ? (
                    <div className="form-container">
                    <span className="message">¿Quieres eliminar tu cuenta?</span>

                        <button
                            onClick={handleDeleteAccount}
                            disabled={isDeleting}
                            className="delete-button"
                        >
                            Delete Account
                        </button>
                    </div>
                ) : (
                    <div className="form-container">
                        <span className="message">Necesitamos que inicies sesión para eliminar tu cuenta y sus datos relacionados.</span>
                        <h5>Más información en nuestra: <a href='/privacy'>Política de privacidad</a></h5>
                        <input
                            type="email"
                            placeholder="Escribe tu email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Escribe tu contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            disabled={!email || !password}
                            onClick={handleLogin}
                            className="login-button"
                        >
                            Iniciar sesión
                        </button>
                    </div>
                )}
            </div>
            <style jsx>{`
                .container {
                    display: flex;
                    justify-content: center; 
                    align-items: center; 
                    height: 100vh;
                    width: 100%;
                    margin: 0;
                }
                .logo {
                    margin-bottom: 0;
                    width: 300px;
                }
                .title {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 70%;
                }
                .delete-button {
                    background-color: #FF4A4A;
                    color: #ffffff;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    margin-bottom: 20px;
                }
                .form-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .message {
                    margin-bottom: 20px;
                    font-size: 18px;
                }
                input {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    border: 1px solid #ced4da;
                    border-radius: 5px;
                }
                .login-button {
                    background-color: #222;
                    color: #ffffff;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    font-size: 16px;
                    cursor: pointer;
                }
                .login-button:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            `}</style>
        </div>
        </div>
    );
};

export default DeleteAccountScreen;
