import React from 'react';
import iosDownloadImage from './assets/app-store.svg';
import androidDownloadImage from './assets/google-play.svg';

const HeroDownload = () => {
  return (
    <div className="hero">
      <h1 className="hero-text">Descarga la app para iOS o Android.</h1>
      <div className="download-buttons">
        <a target='_blank' rel="noreferrer" href='https://apple.co/4bugtF0'>
        <img src={iosDownloadImage} alt="Descarga en App Store" className="download-button download-button-ios" />
        </a>
        <a target='_blank' rel="noreferrer" href='https://play.google.com/store/apps/details?id=com.scala.app'>
        <img src={androidDownloadImage} alt="Descarga para Google Play" className="download-button download-button-play" />
        </a>
      </div>
    </div>
  );
};

export default HeroDownload;
