import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ReactComponent as Logo } from './scala_launch-logo.svg';
import './style.css'; // Asegúrate de importar tus estilos CSS aquí

gsap.registerPlugin(ScrollTrigger);

const HeroMission = () => {
  useEffect(() => {
    const colors = ['#FAE821', '#FF6359', '#FF5892', '#AFA8F9', '#457BFF', '#90DEFF', '#54FF98', '#90DEFF', '#457BFF', '#AFA8F9'];
    
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-mission',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
      }
    });

    colors.forEach((color, index) => {
      timeline.to('.logo-element path', { fill: color, duration: 0.7 }, index);
    });
  }, []);

  return (
    <div className="hero-mission">
      <h1 className="hero-mission-text">
        En <Logo height={30} width={240} className="logo-element" alt="Scala Logo" /> estamos comprometidos con ayudarte a alcanzar una mejor versión de ti mismo.
         Nuestra plataforma te inspira y motiva a lograr tus objetivos personales al mostrarte los logros de otros,
          demostrando que tú también puedes conseguirlo. <br/> <br/> Además, facilitamos la conexión con personas que comparten tus mismos intereses,
           creando una comunidad en la que el apoyo mutuo y el crecimiento son fundamentales. Queremos que descubras tu verdadero potencial,
            y te acompañamos en cada paso del camino hacia tus metas, ofreciéndote las herramientas y el entorno necesario para conseguirlo.
      </h1>
    </div>
  );
};

export default HeroMission;
