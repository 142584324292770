import React from 'react';
import '../App.css';
import LogoFooter from './layouts/LogoFooter';

const Contact = () => {
  return (
    <LogoFooter>
    <div className="container">
      <h1>Contacto</h1>
      <p>Para consultar información o solicitar la eliminación de algún dato o información, puede ponerse en contacto con nuestro equipo en:</p>
      <a href='mailto:info@scala-app.com'>info@scala-app.com</a>
    </div>
    </LogoFooter>
  );
}

export default Contact;
