import React, {useState} from 'react';
import cn from 'classnames';

const FAQData = [
  {
    question: "¿Qué es Scala?",
    answer: `<p>Scala es una plataforma que te ayuda a alcanzar tus objetivos y metas, motivándote a través de los logros de otros y conectándote con personas con intereses similares.</p>
             <p>Usando Scala, en la pestaña <ion-icon name="add-outline"></ion-icon>Crear podrás añadir tus <span class="gradient-text-goal"><ion-icon name="checkmark-outline"></ion-icon>objetivos</span> por cumplir y publicarlos con tus amigos una vez lo cumplas, añadiendo una foto que certifique tu logro, además podrás crear o unirte a <span class="gradient-text-achievement"><ion-icon name="flash-outline"></ion-icon>retos</span>, que son objetivos grupales,
              en los que los demás usuarios podrán unirse y participar contigo. Cada reto en el que seas participante tiene un <ion-icon name="chatbubbles-outline"></ion-icon>chat en el que puedes comunicarte con los otros usuarios del reto.</p>
              <p>En la pestaña <ion-icon name="search-outline"></ion-icon>Explora, encontrarás nuevos perfiles a los que seguir, ideas de nuevos objetivos y retos para que sigas creciendo y logros de otros usuarios con los que inspirarte.</p>
              <p>Podrás editar tu perfil, cambiando datos como tu nombre, tu nombre de usuario o tu foto de perfil. Para protegerte, tienes la posibilidad de bloquear o reportar a otros usuarios, así como ocultar o reportar el contenido.</p>`
  },
  {
    question: "¿Qué puedo crear en Scala?",
    answer: `<p>En Scala podrás crear <span class="gradient-text-goal"><ion-icon name="checkmark-outline"></ion-icon>objetivos</span>, que son metas que nos proponemos nosotros mismos individualmente y que publicaremos una vez lo hayamos cumplido.</p>
    <p>Algunos ejemplos de <span class="gradient-text-goal"><ion-icon name="checkmark-outline"></ion-icon>objetivos</span>, podrían ser: "Viajar a otro país", "Aprender un nuevo idioma", "Alimentarme mejor" ...</p>
    <p>Y también podrás crear <span class="gradient-text-achievement"><ion-icon name="flash-outline"></ion-icon>retos</span>, que son objetivos grupales, en los que los demás usuarios podrán unirse y ayudarnos a cumplirlos.</p> 
    <p>Algunos ejemplos de <span class="gradient-text-achievement"><ion-icon name="flash-outline"></ion-icon>retos</span>, podrían ser: "Hacer un voluntariado", "Recoger basura de la playa", "Torneo deportivo" ...</p> `

  },
  {
    question: "Crear un objetivo",
    answer: `<p>1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
            <p>2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>Crear nuevo'.</p>
              <p>3. Elige una categoría que se ajuste al objetivo que quieras cumplir y pulsa sobre ella.</p>
              <p>4. En el desplegable 'Crear', selecciona <span class="gradient-text-goal"><ion-icon name="checkmark-outline"></ion-icon>Objetivo</span>.</p>
              <p>5. Rellena el formulario añadiendo el título de tu objetivo y la fecha límite.</p>
              <p>6. Pulsa sobre 'Crear objetivo'</p>

              <br/> <p>Tu objetivo se ha creado y se encuentra en la pantalla <ion-icon name="add-outline"></ion-icon>Crear, una vez hayas cumplido tu objetivo, podrás publicarlo.</p>
              `
  },

  {
    question: "Publicar un objetivo",
    answer: `<p>1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
            <p>2. Pulsa en el botón '<ion-icon name="checkmark-outline"></ion-icon>'  del objetivo que hayas cumplido.</p>
              <p>3. En el desplegable pulsa sobre 'Publicar'</span>.</p>
              <p>4. Añade una imagen que certifique que has cumplido el objetivo.</p>
              <p>5. Pulsa sobre 'Publicar'</p>

              <br/> <p>Tu objetivo se ha creado y se encuentra en la pantalla '<ion-icon name="add-outline"></ion-icon>Crear'</p>
              `
  },

  {
    question: "Crear un reto",
    answer: `<p>1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
            <p>2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>Crear nuevo'.</p>
              <p>3. Elige una categoría que se ajuste al reto que quieras crear y pulsa sobre ella.</p>
              <p>4. En el desplegable 'Crear', selecciona <span class="gradient-text-achievement"><ion-icon name="flash-outline"></ion-icon>Reto</span>.</p>
              <p>5. Rellena el formulario añadiendo el título de tu reto, la fecha y hora en que se realizará, la ubicación (es opcional) y añade una descripción detallada de lo que será tu reto para que los demás participantes puedan estar informados y se unan.</p>
              <p>6. Pulsa sobre 'Añadir imagen'</p>
              <p>7. Añade una imagen sobre tu reto</p>
              <p>8. Pulsa sobre 'Publicar'</p>

              <br/> <p>Tu reto se ha creado y se encuentra en la pantalla de '<ion-icon name="home-outline"></ion-icon>Inicio' y en la pantalla de '<ion-icon name="flash-outline"></ion-icon>Chats', los demás usuarios podrán unirse a tu reto y hablar por el chat.</p>
              `
  },
  {
    question: "Bloquear o desbloquear un perfil en Scala",
    answer: `<p>Si bloqueas a alguien, no podrá ver nada de lo que publiques en Scala. La persona podrá ver que lo has bloqueado si entra en tu perfil.</p>
            <p>Para bloquear a alguien sigue los siguientes pasos:</p>
            <p>1. Ve al perfil de la persona que quieras bloquear.</p>
            <p>2. Pulsa en '<ion-icon name="ellipsis-vertical"></ion-icon>'.</p>
              <p>3. En el desplegable pulsa sobre '<ion-icon name="ban-outline"></ion-icon>Bloquear usuario'</span>.</p>

              <br/> <p>Si desbloqueas a alguien, volverá ver lo que publiques en Scala.</p>
              <p>Para desbloquear a alguien sigue los siguientes pasos:</p>
              <p>1. Ve al perfil de la persona que has bloqueado.</p>
              <p>2. Pulsa en '<ion-icon name="ellipsis-vertical"></ion-icon>'.</p>
                <p>3. En el desplegable pulsa sobre '<ion-icon name="ban-outline"></ion-icon>Desbloquear usuario'</span>.</p>
              `
  },
];

const HeroFAQ = () => {

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="hero-faq">
      <h1 className="hero-text">¿Tienes alguna duda?</h1>
      <div className='gradient-text'>
      <h2 className="hero-subtext">Estamos aquí para ayudarte</h2>
      </div>
      <div className="accordion">
      {FAQData.map((item, index) => (
        <div key={index} className="accordion-item">
          <div 
            className="accordion-question" 
            onClick={() => handleToggle(index)}
          >
            {item.question}
            <ion-icon 
              name={activeIndex === index ? 'remove-outline' : 'add-outline'} 
              className="accordion-icon"
            ></ion-icon>
          </div>
          <div 
            className={cn("accordion-answer", { 
              'accordion-answer-open': activeIndex === index,
              'accordion-answer-closed': activeIndex !== index 
            })}
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        </div>
      ))}
    </div>
    </div>
  );
};

export default HeroFAQ;
