import React, { useEffect } from "react";
import { gsap } from "gsap";
import logo from './scala_launch-logo.svg';
import logo_sc from '../../logo.svg';
import WaitlistButton from '../WaitlistButton'
import WaitlistButtonMobile from '../WaitlistButtonMobile'

export default function HeroHeader() {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo(
      "#hero-logo-sc",
      { opacity: 0 },
      { opacity: 1, duration: 0.5, delay: 0.1 }
    );
    // Animar el logo primero
    tl.fromTo(
      "#hero-logo",
      { opacity: 0 },
      { opacity: 1, duration: 0.5, delay: 0.1 },
      "-=0.2"
    );

    // Luego animar el texto, comenzando un poco antes de que termine la animación del logo
    tl.fromTo(
      "#hero-text",
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      "-=0.2" // Empieza la animación del texto 1 segundo antes de que termine la animación del logo
    );
    tl.fromTo(
      "#hero-button",
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      "-=0.2"
    );
    tl.fromTo(
      ".button-top-xl",
      { "--blur-value": "500px", },
      { "--blur-value": "15px",  duration: 0.6},
      "-=0.3"
    );
  }, []);

  return (
    <div className="hero-text-section">
      <div className="hero-logo-section"><img id="hero-logo-sc" src={logo_sc} className="logo-top-element" alt="" /></div>
      <img id="hero-logo" src={logo} className="logo-element" alt="" />
      <h1 id="hero-text">Conecta con el éxito,<br />inspira con tus logros</h1>
      <WaitlistButton/>
      <WaitlistButtonMobile/>
    </div>
  );
}
