import React, { useState } from 'react';
import { gsap } from 'gsap';
import '../../App.css'; // Importa tus estilos CSS aquí
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const FormAnimation = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Unirme a la lista de espera');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleButtonClick = async () => {
    setFormOpen(true); // Abrir el formulario al hacer clic en el botón
    setButtonText("Enviar")

    gsap.to('.form-slide-xl', {  opacity: 1, width: "250px", left: "calc(50% - 167px)", duration: 0.5, ease: 'power2.inOut' });
    gsap.to('.button-top-xl', {  opacity: 1, width: "80px", top: "20px", left: "calc(50% + 127px)", duration: 0.5, ease: 'power2.inOut' });
    if (formOpen) {
      try {
        const waitlistRef = firebase.firestore().collection('waitlist').doc(email);
        const waitlistDoc = await waitlistRef.get();
        if (!waitlistDoc.exists) {
          const emailParam = encodeURIComponent(email);
          const apiUrl = `${process.env.REACT_APP_EMAIL_API_URL}?email=${emailParam}`;
  
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const data = {
            email: email,
            date: new Date()
          };
  
          await waitlistRef.set(data);
  
          if (response.ok) {
            setLoading(false);
            setEmailSent(true);
            setButtonText('¡Estás en la lista!');
            gsap.to('.form-slide-xl', { opacity: 0, width: "0px", left: "calc(50% - 145px)", duration: 0.2, ease: 'power2.inOut' });
            gsap.to('.button-top', { opacity: 1, width: "180px", left: "50%", duration: 0.3, ease: 'power2.inOut' });
            gsap.to('.email-sent', { width: '230px', opacity: 1, duration: 0.3, ease: 'power2.inOut', delay: 2 });
            gsap.fromTo('.form-row', { width: 'auto', opacity: 1 }, { width: '100%', opacity: 0, duration: 0.3, delay: 2.3 });
          } else {
            console.error('Failed to send email');
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
        setLoading(false);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Formulario enviado');
  };

  return (
     <div className="top-form-xl-mobile">
        <input className={`input input-top input-top-xl form-slide-xl ${formOpen ? 'active' : ''}`} type="email"
        placeholder="Escribe tu email"
        value={email}
        onChange={handleEmailChange} />
        <button id='hero-button' className='button button-top button-top-xl' type='submit' disabled={loading || emailSent} onClick={handleButtonClick} value='Enviar' > 
      {loading ? 'Enviando...' : buttonText} </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
        {emailSent && <div className="email-sent suc">Revisa tu bandeja de spam :)</div>}
      </div>
  );
};

export default FormAnimation;
