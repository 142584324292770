import React from 'react';
import logo from '../logo3dheader.svg';
import '../../App.css';

const Header = () => {
    return (
        <header>
            <a href='/'>
            <img src={logo} alt="Scala" className="header-logo" />
            </a>
        </header>
    );
};

export default Header;
